import { createRouter, createWebHashHistory } from 'vue-router'
// 首页
// import login from '../views/login.vue'

// import tab from '../components/tab.vue'


const routes = [
// 首页
  // {
  //   path: '/',
  //   name: 'login',
  //   component: login
  // },
  /**
   *  登录:'/'
   * */
  {
    path: '/',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/home',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/index.vue')
  },
  {
    path: '/ceshi',
    name: 'erjicaidan',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/ceshi.vue')
  },

  // {
  //   path: '/dashboard',
  //   redirect: '/dashboard',
  //   name: 'workOrderManagement',
  //   component: () => import(/* webpackChunkName: "about" */ '@/components/index.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name: 'justiceOrderList',
  //       component: () => import('../components/dataList.vue'),
  //     }
  //
  //   ]
  // },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
