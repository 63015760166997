import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
// 引入px自动转换rem适配移动端
import 'amfe-flexible'
// 全局vnt，本项目是vue3+vant-ui开发的
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'

createApp(App)
  .use(router)
  .use(ElementPlus, { locale: zhCn })
  .mount('#app')